var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{staticClass:"product-detail",attrs:{"spinning":_vm.loading}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]),_c('a-divider'),_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"基础信息"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product.id']),expression:"['product.id']"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[0].id']),expression:"['stock_policies[0].id']"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[0].auto', { initialValue: true }]),expression:"['stock_policies[0].auto', { initialValue: true }]"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[0].sku_ids', { initialValue: [] }]),expression:"['stock_policies[0].sku_ids', { initialValue: [] }]"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'stock_policies[0].type',
              { initialValue: 'global' }
            ]),expression:"[\n              'stock_policies[0].type',\n              { initialValue: 'global' }\n            ]"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[1].id']),expression:"['stock_policies[1].id']"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[1].auto', { initialValue: true }]),expression:"['stock_policies[1].auto', { initialValue: true }]"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[1].sku_ids', { initialValue: [] }]),expression:"['stock_policies[1].sku_ids', { initialValue: [] }]"}]}),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['stock_policies[1].type', { initialValue: 'user' }]),expression:"['stock_policies[1].type', { initialValue: 'user' }]"}]})]),_c('a-form-item',{attrs:{"label":"商品名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.name',
              {
                rules: [
                  { required: true, message: '请填写商品名称！' },
                  { max: 80, message: '请输入不超过 80 个字符！' }
                ]
              }
            ]),expression:"[\n              'product.name',\n              {\n                rules: [\n                  { required: true, message: '请填写商品名称！' },\n                  { max: 80, message: '请输入不超过 80 个字符！' }\n                ]\n              }\n            ]"}],attrs:{"auto-size":{ minRows: 2, maxRows: 2 }}})],1),_c('a-form-item',{attrs:{"label":"标语","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.slogan',
              {
                rules: [{ max: 80, message: '请输入不超过 80 个字符！' }]
              }
            ]),expression:"[\n              'product.slogan',\n              {\n                rules: [{ max: 80, message: '请输入不超过 80 个字符！' }]\n              }\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"描述","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.description',
              {
                rules: [
                  { required: true, message: '请填写商品描述！' },
                  { max: 2000, message: '请输入不超过 2000 个字符！' }
                ]
              }
            ]),expression:"[\n              'product.description',\n              {\n                rules: [\n                  { required: true, message: '请填写商品描述！' },\n                  { max: 2000, message: '请输入不超过 2000 个字符！' }\n                ]\n              }\n            ]"}],attrs:{"withImage":""}})],1),_c('a-form-item',{attrs:{"label":"阶段","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.stage',
              {
                initialValue: _vm.stage
              }
            ]),expression:"[\n              'product.stage',\n              {\n                initialValue: stage\n              }\n            ]"}]},_vm._l((_vm.ProductStage),function(value,key){return _c('a-radio',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.stage === 'pre_sell'),expression:"stage === 'pre_sell'"}],attrs:{"label":"预售时间","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.sale_at',
              {
                rules: [
                  {
                    required: _vm.stage === 'pre_sell',
                    message: '请选择预售时间！'
                  }
                ]
              }
            ]),expression:"[\n              'product.sale_at',\n              {\n                rules: [\n                  {\n                    required: stage === 'pre_sell',\n                    message: '请选择预售时间！'\n                  }\n                ]\n              }\n            ]"}],attrs:{"show-time":"","valueFormat":"YYYY-MM-DDTHH:mm:ssZ"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.stage === 'pre_sell'),expression:"stage === 'pre_sell'"}],attrs:{"label":"发货时间","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.deliver_at',
              {
                rules: [
                  {
                    required: _vm.stage === 'pre_sell',
                    message: '请选择发货时间！'
                  }
                ]
              }
            ]),expression:"[\n              'product.deliver_at',\n              {\n                rules: [\n                  {\n                    required: stage === 'pre_sell',\n                    message: '请选择发货时间！'\n                  }\n                ]\n              }\n            ]"}],attrs:{"show-time":"","valueFormat":"YYYY-MM-DDTHH:mm:ssZ"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.stage === 'crowdfunding'),expression:"stage === 'crowdfunding'"}],attrs:{"label":"站外链接","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.url',
              {
                rules: [
                  {
                    required: _vm.stage === 'crowdfunding',
                    message: '请填写站外链接！'
                  }
                ]
              }
            ]),expression:"[\n              'product.url',\n              {\n                rules: [\n                  {\n                    required: stage === 'crowdfunding',\n                    message: '请填写站外链接！'\n                  }\n                ]\n              }\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"状态","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.status',
              {
                initialValue: 'pending'
              }
            ]),expression:"[\n              'product.status',\n              {\n                initialValue: 'pending'\n              }\n            ]"}]},_vm._l((_vm.ProductStatus),function(value,key){return _c('a-radio',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"置顶","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_top',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_top',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"排序权重","help":"数值越大，排序越靠前","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product.priority']),expression:"['product.priority']"}],attrs:{"precision":0}})],1),_c('a-form-item',{attrs:{"label":"隐藏","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_hidden',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_hidden',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHidden),expression:"isHidden"}],attrs:{"label":"专属隐藏 ID","help":"建议使用字母和数字组合","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.hidden_id',
              {
                rules: [
                  {
                    required: _vm.isHidden,
                    message: '请填写专属隐藏 ID！'
                  },
                  {
                    validator: function (rule, value, callback) {
                      if (!/^[A-Za-z0-9_]*$/.test(value)) {
                        callback('活动 ID 只能为英文和数字组合');
                        return;
                      }
                      callback();
                    }
                  }
                ]
              }
            ]),expression:"[\n              'product.hidden_id',\n              {\n                rules: [\n                  {\n                    required: isHidden,\n                    message: '请填写专属隐藏 ID！'\n                  },\n                  {\n                    validator: (rule, value, callback) => {\n                      if (!/^[A-Za-z0-9_]*$/.test(value)) {\n                        callback('活动 ID 只能为英文和数字组合');\n                        return;\n                      }\n                      callback();\n                    }\n                  }\n                ]\n              }\n            ]"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHidden),expression:"isHidden"}],attrs:{"label":"专属隐藏链接","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{staticStyle:{"background-color":"transparent","color":"inherit","cursor":"text"},attrs:{"disabled":"","value":_vm.url.productsHidden + _vm.hiddenId}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAdd),expression:"!isAdd"}],attrs:{"label":"开启白名单","help":"开启白名单后，只有白名单内的用户可以下单购买此商品","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_use_whitelist',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_use_whitelist',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"赠送 MIAOCOIN","help":"开启赠送后，用户下单购买此商品将获得对应的 MIAOCOIN","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_allow_send_coin',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_allow_send_coin',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}],attrs:{"disabled":!_vm.isAdd}})],1),_c('a-form-item',{attrs:{"label":"虚拟商品","help":"开启虚拟商品后，此商品 SKU 只能绑定对应优惠券或者必购码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_virtual',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_virtual',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}],attrs:{"disabled":!_vm.isAdd}})],1),_c('a-form-item',{attrs:{"label":"MIAOCOIN 支付","help":"开启 MIAOCOIN 支付后，用户下单购买此商品将使用 MIAOCOIN 进行支付","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_coin_pay',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_coin_pay',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}],attrs:{"disabled":!_vm.isAdd}})],1),_c('a-form-item',{attrs:{"label":"商品分享","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.is_share',
              {
                initialValue: true,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'product.is_share',\n              {\n                initialValue: true,\n                valuePropName: 'checked'\n              }\n            ]"}]}),_c('span',{staticStyle:{"cursor":"pointer","margin-left":"20px"},on:{"click":_vm.exportShare}},[_c('a-icon',{staticClass:"download",attrs:{"type":"download"}}),_vm._v(" 下载分享数据 ")],1)],1),_c('h2',[_vm._v("媒体")]),_c('a-divider'),_c('a-form-item',{attrs:{"label":"主图","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-media-list',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.main_medias',
              {
                initialValue: [],
                rules: [{ required: true, message: '请上传主图！' }]
              }
            ]),expression:"[\n              'product.main_medias',\n              {\n                initialValue: [],\n                rules: [{ required: true, message: '请上传主图！' }]\n              }\n            ]"}],attrs:{"length":10}})],1),_c('a-form-item',{attrs:{"label":"详情图","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-media-list',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.detail_medias',
              {
                initialValue: [],
                rules: [{ required: true, message: '请上传详情图！' }]
              }
            ]),expression:"[\n              'product.detail_medias',\n              {\n                initialValue: [],\n                rules: [{ required: true, message: '请上传详情图！' }]\n              }\n            ]"}],attrs:{"length":20}})],1),_c('a-form-item',{attrs:{"label":"群二维码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-media-list',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'product.qr_code_images',
              {
                initialValue: []
              }
            ]),expression:"[\n              'product.qr_code_images',\n              {\n                initialValue: []\n              }\n            ]"}],attrs:{"length":1}})],1),_c('h2',[_vm._v("SKU")]),_c('a-divider'),_c('a-form-item',{attrs:{"label":"总库存","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'stock_policies[0].max_stock',
              {
                rules: [{ required: true, message: '请填写总库存！' }]
              }
            ]),expression:"[\n              'stock_policies[0].max_stock',\n              {\n                rules: [{ required: true, message: '请填写总库存！' }]\n              }\n            ]"}],attrs:{"min":1,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"总限购数","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'stock_policies[1].max_stock',
              {
                rules: [
                  {
                    required: true,
                    message: '请填写总限购数！'
                  }
                ]
              }
            ]),expression:"[\n              'stock_policies[1].max_stock',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请填写总限购数！'\n                  }\n                ]\n              }\n            ]"}],attrs:{"min":1,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"多属性","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-attr-list',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'attrs',
              {
                initialValue: [],
                rules: [{ required: true, message: '请设置多属性！' }]
              }
            ]),expression:"[\n              'attrs',\n              {\n                initialValue: [],\n                rules: [{ required: true, message: '请设置多属性！' }]\n              }\n            ]"}],attrs:{"length":3}})],1),(!_vm.isVirtual)?_c('a-form-item',{attrs:{"label":"批量库存","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{attrs:{"min":0,"precision":0},on:{"blur":function($event){return _vm.onStockChange($event.target.value || 0)},"pressEnter":function($event){return _vm.onStockChange($event.target.value || 0)}}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"批量价格","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{attrs:{"min":1,"precision":0},on:{"blur":function($event){return _vm.onAmountChange($event.target.value || 1)},"pressEnter":function($event){return _vm.onAmountChange($event.target.value || 1)}}})],1),_c('a-form-item',[_c('v-sku-list',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'skus',
              {
                initialValue: [],
                rules: [{ required: true, message: '请设置 SKU！' }]
              }
            ]),expression:"[\n              'skus',\n              {\n                initialValue: [],\n                rules: [{ required: true, message: '请设置 SKU！' }]\n              }\n            ]"}],attrs:{"productId":_vm.product.product.id,"productName":_vm.product.product.name,"isVirtual":_vm.isVirtual,"isCoinPay":_vm.isCoinPay}})],1)],1),_c('a-divider'),_c('div',{staticClass:"actions"},[_c('a-button',{staticClass:"submit",attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 完成 ")]),_c('a-button',{staticClass:"close",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 返回 ")])],1)],1),(!_vm.isAdd && _vm.isUseWhitelist)?_c('a-tab-pane',{key:"2",attrs:{"tab":"白名单"}},[_c('v-product-white-list')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }