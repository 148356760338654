












































































































































































































































































































































































































































































































































import Vue from "vue";
import moment from "moment";
import { dispatch, Product, Sku } from "@/store";
import { ProductStage, ProductStatus } from "@/enum";
import { url } from "@/api";
import { download, xlsx } from "@/utils";

export default Vue.extend({
  data() {
    return {
      moment,
      url,
      loading: false,
      form: this.$form.createForm(this),
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      product: { product: {} } as Product,
      ProductStage: ProductStage,
      ProductStatus: ProductStatus,
      stage: "conceptual",
      isHidden: false,
      isVirtual: false,
      isCoinPay: false,
      hiddenId: "",
      isUseWhitelist: false
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    isAdd() {
      return !this.$route.params.id;
    }
  },
  methods: {
    onValuesChange(props: any, product: Product) {
      if (product.product) {
        const {
          stage,
          is_hidden,
          hidden_id,
          is_use_whitelist,
          is_virtual,
          is_coin_pay
        } = product.product;
        this.stage = stage || this.stage;
        this.isHidden =
          typeof is_hidden === "boolean" ? is_hidden : this.isHidden;
        this.hiddenId = hidden_id || this.hiddenId;
        this.isUseWhitelist =
          typeof is_use_whitelist === "boolean"
            ? is_use_whitelist
            : this.isUseWhitelist;
        this.isVirtual =
          typeof is_virtual === "boolean" ? is_virtual : this.isVirtual;
        this.isCoinPay =
          typeof is_coin_pay === "boolean" ? is_coin_pay : this.isCoinPay;
      }
      const attrs = (product.attrs || []).filter(
        item => item.name && item.values.length
      );
      if (attrs.length && !product.skus) {
        let skus: Sku[] = this.form.getFieldValue("skus");
        skus = skus.filter(sku => sku.id);
        const optionsList: any[][] = [[]];
        attrs.map(attr => {
          if (attr.name && attr.values.length) {
            optionsList.splice(0, optionsList.length).map(options => {
              attr.values.map(value => {
                optionsList.push([
                  ...options,
                  {
                    name: attr.name,
                    value
                  }
                ]);
              });
            });
          }
        });
        optionsList.map(options => {
          const sameSku = skus.find(sku => {
            return JSON.stringify(sku.options) === JSON.stringify(options);
          });
          if (sameSku) {
            return;
          } else {
            let name = "";
            options.map(option => {
              name += `${option.name}:${option.value}; `;
            });
            skus.push({
              amount: 1,
              coin: 1,
              extra_amount: 0,
              is_active: true,
              limit: 1,
              name,
              medias: [],
              options,
              priority: 0,
              stock: 0
            });
          }
        });
        this.form.setFieldsValue({ skus });
      }
    },
    onStockChange(value: string) {
      const skus: Sku[] = this.form.getFieldValue("skus");
      skus.map(sku => {
        sku.stock = Number(value);
      });
      this.form.setFieldsValue({ skus });
    },
    onAmountChange(value: string) {
      const skus: Sku[] = this.form.getFieldValue("skus");
      skus.map(sku => {
        if (this.isCoinPay) {
          sku.coin = Number(value);
        } else {
          sku.amount = Number(value);
        }
      });
      this.form.setFieldsValue({ skus });
    },
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.form.validateFieldsAndScroll(
        { scroll: { offsetTop: 200 } },
        (errors, values) => {
          if (!errors) {
            const product: Product = {
              ...values
            };
            if (
              product.product.is_allow_send_coin &&
              product.product.is_coin_pay
            ) {
              this.$message.error("MIAOCOIN 支付商品不能赠送 MIAOCOIN");
              return;
            }
            this.loading = true;
            if (this.isAdd) {
              dispatch
                .productsPost(product)
                .then(() => {
                  this.$message.success("商品已添加");
                  this.$router.push(this.$paths.products);
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              dispatch
                .productsPatch(product)
                .then(() => {
                  this.$message.success("商品已修改");
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          }
        }
      );
    },
    exportShare() {
      dispatch.productsGetShare(Number(this.id)).then(res => {
        console.log();
        const data = [
          [
            "产品 ID",
            "分享用户 ID",
            "接收用户 ID",
            "分享销售额",
            "分享次数",
            "分享码",
            "分享时间"
          ]
        ];
        res.results.map((item: any) => {
          const row = [
            item.product_id,
            item.share_user_id,
            item.scan_user_id,
            item.share_sale_amount,
            item.share_num,
            item.share_code,
            moment(item.share_time).format("YYYY-MM-DD HH:mm:ss")
          ];
          data.push(row);
        });
        const file = xlsx([
          {
            name: "分享数据",
            data,
            cols: [
              { wch: 12 },
              { wch: 12 },
              { wch: 12 },
              { wch: 12 },
              { wch: 12 },
              { wch: 12 },
              { wch: 20 }
            ]
          }
        ]);
        download(file, this.product.product.name + "分享数据统计.xlsx");
      });
    }
  },
  mounted() {
    this.form = this.$form.createForm(this, {
      onValuesChange: this.onValuesChange
    });
    if (this.id) {
      this.loading = true;
      dispatch.productsGet(Number(this.id)).then((product: Product) => {
        this.product = product;
        this.form.setFieldsValue({
          ...product
        });
        this.loading = false;
      });
    }
  }
});
